<template>
  <v-dialog
    v-model="modal"
    width="80%"
    @click:outside="$emit('update:modal', false)"
  >
    <v-card class="modal" :dark="$dark.get()">
      <v-card-title> Создание графика </v-card-title>
      <v-card-text class="modal__text">
        <v-form ref="form">
          <v-text-field
            v-model="input.name"
            type="text"
            label="Название"
            :rules="[$validate.required]"
          />
          <v-text-field
            v-model="input.restDays"
            type="number"
            label="Выходные дни"
            :rules="[$validate.required]"
          />
          <v-text-field
            v-model="input.workingDays"
            type="number"
            label="Рабочие дни"
            :rules="[$validate.required]"
          />
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="$emit('update:modal', false)">Закрыть</v-btn>
        <v-btn color="primary" @click="createTimeShedule()">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import showMessage from "@/Functions/message";
import api from "@/api";

export default {
  name: "CreateModal",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: {},
    };
  },
  watch: {
    modal(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    },
  },
  methods: {
    async createTimeShedule() {
      if (!this.$refs.form.validate()) {
        showMessage("Заполните все поля");
        return;
      }
      const response = await api.Timetable.createTimeShedule(this.input);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("График создан", true);
        this.$emit("rerender");
        this.$emit("update:modal", false);
      }
    },
  },
};
</script>
