export default class test {
  constructor() {
    this.input = {
      breakAmountPlan: null,
      breakTimePlan: null,
      departmentId: null,
      id: null,
      roleId: null,
      timeBegin: "",
      timeEnd: "",
    };
    this.body = [];
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      this.input.id = data.id;
      this.input.breakAmountPlan = data.breakAmountPlan;
      this.input.breakTimePlan = data.breakTimePlan;
      this.input.roleId = data.role.id;
      this.input.timeBegin = data.timeBegin;
      this.input.timeEnd = data.timeEnd;
      this.input.departmentId = data.department.id;
    }
    return this.input;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.length; i++) {
        this.body.push({
          fields: [
            {
              id: 1,
              name: data[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data[i].role.name,
              type: "string",
            },
            {
              id: 3,
              name: "С " + data[i].timeBegin + " до " + data[i].timeEnd,
              type: "string",
            },
            {
              id: 4,
              name: data[i].breakTimePlan + " минут",
              type: "string",
            },
            {
              id: 5,
              name: data[i].breakAmountPlan + " раз(-а)",
              type: "string",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
