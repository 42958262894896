<template>
  <div class="about">
    <loader v-if="loading"></loader>
    <table-component
      v-if="old && !loading"
      :items="items"
      :edit="true"
      :delet="true"
      :select="USER.userInfo.departments"
      :sel-id="depId"
      @delete-button="deleteButton"
      @set-select="
        depId = $event;
        setBody(depId);
      "
    >
      <v-btn color="primary" @click="old = false">Новый график</v-btn>
    </table-component>
    <v-card v-if="!old && !loading" class="shedule">
      <v-card-title class="shedule_header">
        <h4>Новый график работы</h4>
        <div class="shedule_header__box">
          <v-btn color="primary" @click="old = true">Старый график</v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-large
                icon
                color="success"
                v-bind="attrs"
                v-on="on"
                @click="showCreateModal = true"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>Добавить график</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-text>
        <v-simple-table height="64vh" fixed-header>
          <thead>
            <tr>
              <th>Название</th>
              <th>Дней отдыха</th>
              <th>Дней работы</th>
              <th>Управление</th>
            </tr>
          </thead>
          <tbody style="max-height: 92vh; min-height: 64vh">
            <tr v-for="item in TIMETABLE.timeShedule" :key="item.id">
              <td>{{ item.name }}</td>
              <td>{{ item.restDays }}</td>
              <td>{{ item.workingDays }}</td>
              <td>
                <v-btn
                  icon
                  color="error"
                  @click="
                    deletedId = item.id;
                    showDeleteModal = true;
                  "
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions> </v-card-actions>
    </v-card>
    <CreateModal :modal.sync="showCreateModal" @rerender="setTimeShedule()" />
    <DeleteDialog
      :show="showDeleteModal"
      @close-modal="showDeleteModal = !showDeleteModal"
      @delete-modal="deleteShedule()"
    />
  </div>
</template>
<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import DeleteDialog from "@/components/DeleteDialog";
import CreateModal from "@/components/TimeTable/CreateModal";
import timetable from "@/model/timetable.js";
import { mapActions, mapGetters } from "vuex";
import api from "@/api";
import showMessage from "@/Functions/message";
export default {
  components: {
    TableComponent,
    Loader,
    DeleteDialog,
    CreateModal,
  },
  data() {
    return {
      loading: true,
      showDeleteModal: false,
      showCreateModal: false,
      deletedId: 0,
      old: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Роль" },
          { id: 3, name: "Смена" },
          { id: 4, name: "Перерыв" },
          { id: 5, name: "Кол-во перерывов" },
        ],
        body: [],
      },
      depId: 5,
    };
  },
  computed: {
    ...mapGetters({
      DEPARTMENT: "Department/STATE",
      TIMETABLE: "Timetable/STATE",
      USER: "User/STATE",
      CITY: "City/STATE",
    }),
  },
  watch: {
    old(val) {
      if (!val && !this.TIMETABLE.timeShedule.length) {
        this.setTimeShedule();
      }
    },
  },
  async created() {
    await this.checkPower();
    await this.getDepartment(this.$root.city);
    if (this.USER.userInfo.departments.length > 0) {
      this.depId = this.USER.userInfo.departments[0].id;
      await this.setBody(this.depId);
    } else {
      this.loading = false;
      this.$notify({
        group: "app",
        type: "error",
        title: "Ошибка",
        text:
          "нет доступных филиалов у данного аккаунта в " +
          this.CITY.allCity.filter((e) => e.id == this.$root.city)[0].name,
      });
    }
  },

  methods: {
    ...mapActions({
      getTimetable: "Timetable/GET_TIMETABLE",
      deleteTimetable: "Timetable/DELETE_TIMETABLE",
      getDepartment: "Department/GET_DEPARTMENT",
      GET_TIME_SHEDULE: "Timetable/GET_TIME_SHEDULE",
    }),
    checkPower() {
      let state = this.$power.check(this.$route.meta.power);
      if (state === false) {
        this.$router.push("/");
      }
    },
    async setBody(id) {
      this.loading = true;
      await this.getTimetable(id);
      let model = new timetable();
      this.items.body = model.setBody(this.TIMETABLE.timtableAll);
      this.loading = false;
    },
    async setTimeShedule() {
      this.loading = true;
      await this.GET_TIME_SHEDULE();
      this.loading = false;
    },
    async deleteButton(item) {
      this.loading = true;
      let errors = [];
      for (let i = 0; i < item.length; i++) {
        let response = await this.deleteTimetable(item[i].id);
        if (response.type !== "success") {
          errors.push(item[i].id);
        }
      }
      if (errors.length !== 0) {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: errors,
        });
      }
      await this.setBody(this.depId);
      this.loading = false;
    },
    async deleteShedule() {
      const response = await api.Timetable.deleteTimeShedule(this.deletedId);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        showMessage("График удален", true);
        this.showDeleteModal = false;
        this.setTimeShedule();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.shedule {
  &_header {
    justify-content: space-between;
    &__box {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}
</style>
